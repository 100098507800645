/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import SEO from "../components/seo"
import { useDispatch } from "react-redux"
import { graphql, StaticQuery } from "gatsby"
import {
  NOTFOUND_PAGE_QUERY_REQUEST,
  NOTFOUND_PAGE_QUERY_SUCCESS,
} from "../store/actionTypes"

import NotFound from "../components/NotFound/NotFound"

const NotFoundPage = () => {
  const dispatch = useDispatch()
  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZToyMTcw") {
          not_found_content {
            buttonlink
            buttontext {
              en
              sv
            }
            image {
              title
              sourceUrl
            }
            pretitle {
              en
              sv
            }
            title {
              en
              sv
            }
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: NOTFOUND_PAGE_QUERY_REQUEST })
        dispatch({ type: NOTFOUND_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <>
            <SEO pageId="cGFnZToyMTcw" />
            <NotFound />
          </>
        )
      }}
    />
  )
}
export default NotFoundPage
