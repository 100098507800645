import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"

const NotFound = () => {
  const not_found_content = useSelector(state => state.notfound)
  const { language } = useSelector(state => state.global)

  return (
    <section>
      <section>
        <div className="erroe_page">
          <div className="error_01">
            <img src={not_found_content.image.sourceUrl} />
          </div>
          <div className="error_02">
            <h2 style={{ textAlign: "center" }} className="m-0">
              {not_found_content.title[language]}
            </h2>
            <p>{not_found_content.pretitle[language]}</p>
            <Link
              to={not_found_content.buttonlink}
              className="common_cls m-auto"
            >
              {not_found_content.buttontext[language]}
              <i className="arrow" />
            </Link>
          </div>
        </div>
      </section>
    </section>
  )
}

export default NotFound
